import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    li: "li",
    p: "p",
    pre: "pre",
    span: "span",
    ul: "ul",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Getting Started: Loader",
      description: "Learn how to use Loaders in a new Firebolt app."
    }), "\n", _jsx(_components.h1, {
      children: "Loaders"
    }), "\n", _jsx(_components.p, {
      children: "Loaders let you fetch data securely from a database or anywhere else, directly inside any component."
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "components/City.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { useLoader } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " 'firebolt'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " City"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "({ "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "id"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " }) {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: " city"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " ="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " useLoader"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "(getCity, id)."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "read"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "()"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ("
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "h1"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">{city.name}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "h1"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "p"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">{city.description}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "p"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " async"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " getCity"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "ctx"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ", "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "id"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ") {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " await"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ctx."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "db"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'cities'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "where"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "({ id })."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "first"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "()"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "Loaders simplify the way you build apps:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Write business logic and access your database right alongside the components that use them."
      }), "\n", _jsxs(_components.li, {
        children: ["They're designed to work with both Suspense and ", _jsx(_components.a, {
          href: "/docs/ref/error-boundary",
          children: "ErrorBoundaries"
        }), " the way React intended."]
      }), "\n", _jsx(_components.li, {
        children: "Loaders work seamlessly via server streaming and on the client."
      }), "\n", _jsx(_components.li, {
        children: "Secure code is stripped completely from all client bundles."
      }), "\n", _jsxs(_components.li, {
        children: ["Forget about writing API endpoints completely (unless you ", _jsx(_components.a, {
          href: "/docs/ref/handler-js",
          children: "need them"
        }), ")"]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      children: "Context & Invalidation"
    }), "\n", _jsxs(_components.p, {
      children: ["All loader functions are called with a ", _jsx(_components.a, {
        href: "/docs/ref/context",
        children: "Context"
      }), " object that allows you to read/write cookies, perform redirects and set an expiration date on the data returned.\nYou can also decorate the context object with access to a database etc in your ", _jsx(_components.a, {
        href: "/docs/ref/config",
        children: "firebolt.config.js"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["If you set data to expire or manually invalidate data, it will be re-fetched in the background and seamlessly update the UI. Learn more on the ", _jsx(_components.a, {
        href: "/docs/ref/useLoader",
        children: "useLoader"
      }), " and ", _jsx(_components.a, {
        href: "/docs/ref/useCache",
        children: "useCache"
      }), " pages."]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
